import * as React from 'react'
import { useCallback } from 'react'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { Layout } from '../layout'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { Migrated } from '../state'
import { useTranslation } from '@local/translations'
import { Logo } from '../logo'
import {
  ContentFooter,
  ContinueButton,
  NavigationContainer,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'

export const Complete = () => {
  const state = useUserMigrationState<Migrated>()
  const { t } = useTranslation()

  const handleContinue = useCallback(() => {
    if (state.returnUrl) {
      const encodedReturnUrl = encodeURIComponent(state.returnUrl)
      window.location.assign(`/Logout.aspx?returnUrl=${encodedReturnUrl}`)
    } else {
      window.location.assign('/Logout.aspx')
    }
  }, [state.returnUrl])

  return (
    <Layout>
      <PageMain>
        <div className='p-10 flex flex-col items-center'>
          <Logo />
          <h1 className='type-headline-2'>{t('complete.youAreAllSetUp')}</h1>
          <p className='mt-8 text-center max-w-sm'>
            <span className='font-bold'>{state.email}</span>{' '}
            {t('complete.isReadyToBeUsedToLogin')}
          </p>
          <CelebrationIllustration />
        </div>

        <ContentFooter>
          <NavigationContainer>
            <ContinueButton onClick={handleContinue} testId='continue'>
              {t('continue')}
            </ContinueButton>
          </NavigationContainer>
        </ContentFooter>
      </PageMain>
    </Layout>
  )
}
