import { RestaurantIcon } from '@toasttab/buffet-pui-icons'
import * as React from 'react'
import { Header } from '@toasttab/buffet-pui-wizard-templates'

export const MigrationHeader = (props: { companyCode: string }) => {
  const { companyCode } = props

  return (
    <Header
      logoRelativeLink={{
        link: '/user/migration',
        label: 'Toast Account Setup'
      }}
      title={companyCode}
      icon={<RestaurantIcon accessibility='decorative' />}
      onRequestClose={() => {
        window.location.assign('/Logout.aspx')
      }}
    />
  )
}
