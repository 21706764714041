import * as React from 'react'
import { useEffect } from 'react'
import { useUserMigration } from './UserMigrationContextProvider'
import { NewAccount, PayrollAccount, ToastAccount } from './account'
import { Complete } from './complete'
import { EmailVerified, VerifyEmail } from './email'
import { Password } from './password'
import { Start } from './start'
import { useUser } from '@toasttab/ec-session'
import { useTranslation } from '@local/translations'
import { EmailConfirmed } from './email/EmailConfirmed'
import { EmailConfirmationError } from './email/EmailConfirmationError'

export const UserMigrationApp = () => {
  const { state } = useUserMigration()
  const user = useUser()
  const { changeLanguage } = useTranslation()

  useEffect(() => {
    changeLanguage(user?.language === 'es' ? 'es-US' : 'en-US')
  }, [user.language, changeLanguage])

  switch (state.type) {
    case 'initial-state':
      return <Start />
    case 'payroll-email':
      return <PayrollAccount />
    case 'toast-email':
      return <ToastAccount />
    case 'new-email':
      return <NewAccount />
    case 'wait-for-email-verification':
      return <VerifyEmail />
    case 'email-verified':
      return <EmailVerified />
    case 'email-confirmed':
      return <EmailConfirmed />
    case 'email-confirmation-error':
      return <EmailConfirmationError />
    case 'wait-for-password':
      return <Password />
    case 'migrated':
      return <Complete />
    default:
      return <></>
  }
}
