import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { PasswordFieldWithRequirements } from '@toasttab/buffet-pui-forms'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from '@local/translations'
import {
  ContentFooter,
  ContentHeader,
  ContinueButton,
  NavigationContainer,
  PageAside,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { Layout } from '../layout'

interface PasswordFormValues {
  password: string
}

export const PasswordView = (props: {
  onContinue: (password: string) => Promise<void>
}) => {
  const { onContinue } = props
  const { t } = useTranslation()

  const passwordSchema = Yup.object().shape({
    password: Yup.string().required(t('password.passwordIsRequired'))
  })

  return (
    <Layout>
      <PageMain>
        <Formik
          initialValues={{
            password: ''
          }}
          validationSchema={passwordSchema}
          onSubmit={async (values: PasswordFormValues) => {
            await onContinue(values.password)
          }}
        >
          <Form>
            <ContentHeader
              title={t('password.createANewPassword')}
              description={t('password.createANewPasswordToUse')}
              contentDivider={true}
            />
            <PasswordFieldWithRequirements
              label={t('password.confirmYourPassword')}
              name='password'
              placeholder={t('password.typePassword')}
            />
            <ContentFooter>
              <NavigationContainer>
                <ContinueButton testId='continue' type='submit'>
                  {t('continue')}
                </ContinueButton>
              </NavigationContainer>
            </ContentFooter>
          </Form>
        </Formik>
      </PageMain>
      <PageAside></PageAside>
    </Layout>
  )
}
