import * as React from 'react'
import { QuestionAnswerDisplayIcon } from '@toasttab/buffet-pui-icons'
import { Account } from './Account'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { NewEmail } from '../state'
import { useTranslation } from '@local/translations'
import { PageInfoList } from '@toasttab/buffet-pui-wizard-templates'

export const NewAccount = () => {
  const state = useUserMigrationState<NewEmail>()
  const { t } = useTranslation()

  return (
    <Account
      source='NEW'
      email={state.email}
      heading={t('newAccount.heading')}
      body={t('newAccount.body')}
      questions={<Questions />}
    />
  )
}

const Questions = () => {
  const { t } = useTranslation()

  return (
    <PageInfoList
      items={[
        {
          Icon: QuestionAnswerDisplayIcon,
          header: t('newAccount.question1'),
          description: t('newAccount.answer1')
        },
        {
          Icon: QuestionAnswerDisplayIcon,
          header: t('newAccount.question2'),
          description: t('newAccount.answer2')
        }
      ]}
    />
  )
}
