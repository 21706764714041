export const translations = {
  skipForNow: 'Skip for now',
  continue: 'Continue',
  resendEmail: 'Resend email',
  editEmail: 'Edit email',
  enterYourEmail: 'Enter your email',
  toastLogin: 'Toast Login',
  anErrorOccurred: 'An error occurred',
  anErrorOccurredRetrying: 'An error occurred. Retrying...',
  oopsWeHaveSomeCleaningUpToDo: 'Oops we have some cleaning up to do',
  oopsSomethingWentWrong: 'Oops, something went wrong',
  questionAndAnswer: 'Question and answer',
  emailLabel: 'Email',
  validEmailRequired: 'A valid email is required',
  start: {
    heading: 'Connect all your Toast accounts to use one login',
    body: 'This will allow you to navigate throughout Toast easily and securely',
    listHeading:
      'By connecting your Payroll account to Toast, you will be able to:',
    listItem1:
      'Use one login to easily navigate between all your Toast products',
    listItem2: 'Manage your information in one place',
    listItem3: 'Know that your personal information is more secure',
    question1: 'Create a login using your email or phone',
    question2: 'Verify new login',
    question3: 'Create a new password',
    whatYouWillNeedToDo: "What you'll need to do"
  },
  newAccount: {
    heading: 'Choose an email to use for all your Toast accounts',
    body: 'Choose a login using your email to login to all your Toast products. You will need to verify the email you choose.',
    question1: 'Can I change my login email?',
    answer1:
      'Yes, you can change your login email, but first we’ll need to verify it’s really you!',
    question2: 'Can I use a username?',
    answer2:
      'Unfortunately not, your personal information is more secure using an email.'
  },
  toastAccount: {
    heading: 'We’ve found a Toast account associated with your email',
    body: 'Connect your Payroll account with your Toast account to easily navigate between your accounts with single sign on. We’ll need to verify it’s really you.',
    question1: 'Can I change my login email?',
    answer1:
      'Yes, you can change your login email, but first we’ll need to verify it’s really you!',
    question2: 'Can I use a username?',
    answer2:
      'Unfortunately not, your personal information is more secure using an email.'
  },
  payrollAccount: {
    heading: 'Use your Payroll login email to login to all your Toast accounts',
    body: 'Connect your Payroll account with your Toast account to easily navigate between your accounts with single sign on. We’ll need to verify it’s really you.',
    question1: 'Can I change my login email?',
    answer1:
      'Yes, you can change your login email, but first we’ll need to verify it’s really you!',
    question2: 'Can I use a username?',
    answer2:
      'Unfortunately not, your personal information is more secure using an email.'
  },
  email: {
    confirm: {
      heading: 'Confirming your email',
      body: "We're confirming your email and setting up your account",
      emailNotVerified: 'Email not verified',
      errorCheckYourEmail:
        'Check your email and use the most recent verification link.'
    },
    confirmed: {
      emailVerified: 'Email verified!',
      isReadyToBeUsed:
        'is ready to be used to login to all of your Toast accounts!'
    },
    verified: {
      emailVerified: 'Email verified! You can close this tab.',
      isVerified:
        'is verified to be used for your Toast account. You can close this window and continue in the new Toast tab to create your new password.'
    },
    verify: {
      checkYourEmail: 'Check your email',
      emailResentTo: 'Email resent to',
      weHaveSentAnEmailTo: 'We have sent an email to',
      toVerifyYourEmailAndConnectYourAccounts:
        'to verify your email to connect your accounts.'
    }
  },
  password: {
    typePassword: 'Type password',
    confirmYourPassword: 'Confirm your password',
    createANewPassword: 'Create a new password',
    passwordIsRequired: 'Password is required',
    createANewPasswordToUse:
      'Create a new password to use to log into your Toast and Payroll account.'
  },
  complete: {
    youAreAllSetUp: "You're all set up!",
    isReadyToBeUsedToLogin: 'is ready to be used to login to Toast products'
  }
}
