import * as React from 'react'
import { useCallback } from 'react'
import { useUserMigrationDispatch } from '../UserMigrationContextProvider'
import {
  SendAccountVerificationEmail,
  VerifyAccountEmail
} from '@local/api/documents'
import { AccountView } from './AccountView'
import { useApolloClient } from '@apollo/client'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { redirectMigratedUser } from '../functions'
import { useTranslation } from '@local/translations'

export const Account = (props: {
  heading: string
  body: string
  email?: string
  source: 'PAYROLL' | 'TOAST' | 'NEW'
  questions?: React.ReactNode
}) => {
  const { email, source } = props
  const dispatch = useUserMigrationDispatch()
  const client = useApolloClient()
  const { showErrorSnackBar } = useSnackBar()
  const { t } = useTranslation()
  const [isSending, setIsSending] = React.useState(false)

  const handleContinue = useCallback(
    async (selectedEmail: string) => {
      setIsSending(true)
      const sendEmail = async () => {
        if (selectedEmail !== undefined) {
          const idempotencyKey = crypto.randomUUID()
          const result = await client.mutate({
            mutation: SendAccountVerificationEmail,
            variables: {
              email: selectedEmail,
              idempotencyKey: idempotencyKey
            }
          })

          const mutationResult =
            result.data?.userMigration?.sendAccountVerificationEmail

          setIsSending(false)

          switch (mutationResult?.__typename) {
            case 'UserMigrationVerificationEmailSent':
              dispatch({
                type: 'account-verification-email-sent',
                email: mutationResult.email,
                source: source,
                idempotencyKey: idempotencyKey
              })
              break
            case 'UserMigrationFailed':
              showErrorSnackBar(mutationResult.message)
              break
            case 'UserAlreadyMigrated':
              redirectMigratedUser()
              break
            default:
              showErrorSnackBar(t('anErrorOccurred'))
              break
          }
        }
      }

      if (selectedEmail !== undefined) {
        const verifyEmail = async () => {
          const result = await client.query({
            query: VerifyAccountEmail,
            variables: {
              email: selectedEmail
            }
          })

          const queryResult = result.data?.userMigration?.verifyAccountEmail

          switch (queryResult?.__typename) {
            case 'UserMigrationEmailVerified':
              dispatch({
                type: 'wait-for-password',
                email: selectedEmail
              })
              break
            case 'UserMigrationEmailNotVerified':
              await sendEmail()
              break
            case 'UserMigrationFailed':
              showErrorSnackBar(queryResult.message)
              break
            case 'UserAlreadyMigrated':
              redirectMigratedUser()
              break
            default:
              showErrorSnackBar(t('anErrorOccurred'))
              break
          }
        }
        await verifyEmail()
      }
    },
    [source, client, dispatch, showErrorSnackBar, t]
  )

  return (
    <AccountView
      {...props}
      email={email}
      onContinue={handleContinue}
      isSending={isSending}
    />
  )
}
