import * as React from 'react'
import { EmailCard } from '../email'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from '@local/translations'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import {
  ContentFooter,
  ContentHeader,
  NavigationContainer,
  PageAside,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { Layout } from '../layout'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'

export interface EmailFormValues {
  email: string
}

const SendButtonIcon = (props: { isSending: boolean }) => {
  const { t } = useTranslation()
  const { isSending } = props

  if (isSending) {
    return <MerryGoRound size='xxs' aria-label={t('continue')} />
  } else {
    return <ArrowForwardIcon aria-label={t('continue')} />
  }
}

export const AccountView = (props: {
  questions?: React.ReactNode
  heading: string
  body: string
  email?: string
  isSending: boolean
  onContinue: (email: string) => Promise<void>
}) => {
  const { questions, heading, body, email, onContinue, isSending } = props
  const { t } = useTranslation()
  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validEmailRequired'))
      .required(t('validEmailRequired'))
  })

  return (
    <Layout>
      <PageMain>
        <Formik
          initialValues={{
            email: email || ''
          }}
          validationSchema={emailSchema}
          onSubmit={async (values: EmailFormValues, actions) => {
            await onContinue(values.email)
            actions.setSubmitting(false)
          }}
        >
          <Form>
            <ContentHeader
              title={heading}
              description={body}
              contentDivider={true}
            />
            <EmailCard email={email} />
            <ContentFooter>
              <NavigationContainer>
                <Button
                  type='submit'
                  testId='continue'
                  disabled={isSending}
                  iconRight={
                    <SendButtonIcon
                      isSending={isSending}
                      aria-label={t('continue')}
                    />
                  }
                >
                  {t('continue')}
                </Button>
              </NavigationContainer>
            </ContentFooter>
          </Form>
        </Formik>
      </PageMain>
      <PageAside>{questions}</PageAside>
    </Layout>
  )
}
