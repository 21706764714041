import * as React from 'react'
import { Layout } from '../layout'
import { useTranslation } from '@local/translations'
import { PageAside, PageMain } from '@toasttab/buffet-pui-wizard-templates'
import { Error500DesktopIllustration } from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

const SendButtonIcon = (props: { isSending: boolean }) => {
  const { t } = useTranslation()
  const { isSending } = props

  if (isSending) {
    return <MerryGoRound size='xxs' aria-label={t('continue')} />
  }
}

export const EmailConfirmationErrorView = (props: {
  isSending: boolean
  onResendEmail: () => void
}) => {
  const { onResendEmail, isSending } = props
  const { t } = useTranslation()

  return (
    <Layout>
      <PageMain>
        <div className='text-center'>
          <Error500DesktopIllustration />
          <h1>{t('oopsSomethingWentWrong')}</h1>
          <p className='mb-6'>{t('email.confirm.errorCheckYourEmail')}</p>
          <div>
            <Button
              onClick={onResendEmail}
              variant='secondary'
              testId='continue'
              disabled={isSending}
              iconRight={
                <SendButtonIcon
                  isSending={isSending}
                  aria-label={t('resendEmail')}
                />
              }
            >
              {t('resendEmail')}
            </Button>
          </div>
        </div>
      </PageMain>
      <PageAside></PageAside>
    </Layout>
  )
}
