import * as React from 'react'
import { QuestionAnswerDisplayIcon } from '@toasttab/buffet-pui-icons'
import { Account } from './Account'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { PayrollEmail } from '../state'
import { useTranslation } from '@local/translations'
import { PageInfoList } from '@toasttab/buffet-pui-wizard-templates'

export const PayrollAccount = () => {
  const state = useUserMigrationState<PayrollEmail>()
  const { t } = useTranslation()

  return (
    <Account
      source='PAYROLL'
      email={state.email}
      heading={t('payrollAccount.heading')}
      body={t('payrollAccount.body')}
      questions={<Questions />}
    />
  )
}

const Questions = () => {
  const { t } = useTranslation()
  return (
    <PageInfoList
      items={[
        {
          Icon: QuestionAnswerDisplayIcon,
          header: t('payrollAccount.question1'),
          description: t('payrollAccount.answer1')
        },
        {
          Icon: QuestionAnswerDisplayIcon,
          header: t('payrollAccount.question2'),
          description: t('payrollAccount.answer2')
        }
      ]}
    />
  )
}
